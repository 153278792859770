<template>
    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :xl="12" :lg="12" :cols="12">
                <competition-visibility-evolution/>
            </v-col>

            <v-col :xl="6" :lg="6" :md="6" :cols="12">
                <competition-top-competitor :display-link-button="false" />
            </v-col>
            <v-col :xl="6" :lg="6" :md="6" :cols="12">
                <competition-competitor-compare/>
            </v-col>

            <v-col :xl="6" :lg="6" :md="6" :cols="12">
                <competition-best-progression/>
            </v-col>
            <v-col :xl="6" :lg="6" :md="6" :cols="12">
                <competition-new-competitor/>
            </v-col>

            <v-col :cols="12">
                <competition-market-leader/>
            </v-col>
        </v-row>
    </page-placeholder>

</template>


<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import CompetitionTopCompetitor from "@/components/yooda-components/app/competition/CompetitionTopCompetitor";
import CompetitionBestProgression from "@/components/yooda-components/app/competition/CompetitionBestProgression";
import CompetitionNewCompetitor from "@/components/yooda-components/app/competition/CompetitionNewCompetitor";
import CompetitionVisibilityEvolution from "@/components/yooda-components/app/competition/CompetitionVisibilityEvolution";
import CompetitionCompetitorCompare from "@/components/yooda-components/app/competition/CompetitionCompetitorCompare";
import CompetitionMarketLeader from "@/components/yooda-components/app/competition/CompetitionMarketLeader";


export default {

    components: {
        PagePlaceholder,
        CompetitionTopCompetitor,
        CompetitionBestProgression,
        CompetitionNewCompetitor,
        CompetitionVisibilityEvolution,
        CompetitionCompetitorCompare,
        CompetitionMarketLeader,
    },
}

</script>

